$(window).on("load", function () {
  fadeOutLoadingPage();
  // vegas
  $("#banner").vegas({
    slides: [{ src: "./images/banner-bg.jpg" }],
    timer: false,
    autoplay: true,
    transition: "fade",
    transitionDuration: 3000,
    animation: "random",
    animationDuration: "10000",
  });
  //animate
  new WOW({
    boxClass: "wow", // default
    animateClass: "animated", // default
    offset: 100, // default
    mobile: true, // default
    live: true, // default
  }).init();
});
$(function () {
  $(".menu a").on("click", function (e) {
    e.preventDefault();
    slideToSection(this);
    $("#offcanvasNavbar .btn-close").trigger("click");
  });
  $(window).on("scroll", rotateBcard);
  $(window).on("scroll", markMenuArriveItem);
  $(window).on("scroll", function () {
    if (isSectionAllShow(".advatage-items")) animateSection(".advatage-items");
  });
});

function slideToSection(el) {
  let target = $(el).attr("href");

  // 關於我們上方多留一些區塊
  if (target == "#about-us") {
    $("html,body").animate(
      {
        scrollTop:
          $(target).offset().top -$(window).outerHeight() + $(target).outerHeight() - 1,
      },
      0
    );
    return;
  }

  if (target == "#advantage") {
    $("html,body").animate(
      {
        scrollTop:
          $(target).offset().top -
          ($(document).outerHeight() -
            ($(".main-content").outerHeight() + $(".footer").outerHeight()) + 1),
      },
      0
    );
    return;
  }
  $("html,body").animate(
    {
      scrollTop:
        $(target).offset().top -
        ($(document).outerHeight() -
          ($(".main-content").outerHeight() + $(".footer").outerHeight())),
    },
    0
  );
}

function rotateBcard() {
  if (isSectionAllShow(".business-card")) {
    $(".business-card").addClass("open");
    $(window).off("scroll", rotateBcard);
  }
}

function isSectionAllShow(target) {
  let end =
    $(target).offset().top - $(window).height() + $(target).outerHeight();
  return $(window).scrollTop() >= end;
}

function markMenuArriveItem() {
  $(".menu li a").each(function () {
    let target = $(this).attr("href");
    if (isSectionArrive(target)) {
      $(this).addClass("arrive");
      $(this).parent().siblings().find("a").removeClass("arrive");
    }
  });
}

function isSectionArrive(target) {
  // console.log(`
  // 區塊距離上方距離: ${$(target).offset().top},
  // 區塊高度: ${$(target).outerHeight()},
  // 視窗高度: ${$(window).height()},
  // 目前滾動高度: ${$(window).scrollTop()},
  // `);
  let isStart =
    $(window).scrollTop() + $(window).height() >= $(target).offset().top;
  let isEnd =
    $(window).scrollTop() + $(window).height() <
    $(target).offset().top + $(target).outerHeight();
  let isInRange = isStart && isEnd;
  return isInRange;
}

function fadeOutLoadingPage() {
  $("#loading-page").fadeOut(800);
  $("body").addClass("onload");
}

function animateSection(target) {
  $(target).addClass("animated-section");
}
